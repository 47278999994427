.App {
  text-align: center;
}

.App-header {
  background-color: #ffcc00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #111;
}

.main_image {
	width:300px;
  height:300px;
  object-fit:cover;
	border-radius:50%;
}

.small_image {
	width:125px;
  height:125px;
  object-fit:cover;
	border-radius:50%;
}

.title_header {
	padding-left: 25px;
}

.display_date {
	font-size: 20px;
	margin-top: -20px;
	padding-left: 30px;
}

.section_header {
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 30px;
	margin-top: 30px;

}

.main_question {
	font-family: Montserrat !important;
}

.font-mont {
	font-family: Montserrat !important;

}
.sign-in-button-div {
	margin-top: 50px;
}

.text-center {
	text-align: center;
}

.sign-in-button {
	width: 200px;
}

.test-link-div {
	margin-bottom: 50px;
}


.test-info {
	color: white;
	font-size: 18px;
}

.test-link {
	color: greenyellow;
	font-size: 18px;
	text-decoration: none;
	margin-bottom: 50px;
}

.test-link:hover {
	color: greenyellow;
	text-decoration: underline;
}

#dashboard {
  text-align: center;
  border-collapse: collapse;
	border: 1px solid #ddd;
	width: 100%;
}

#dashboard td, #dashboard th {
  border: 1px solid #ddd;
	padding: 10px;
	background-color: orange;
}


#dashboard th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
	color: white;
	background-color: darkblue;
}

#dashboard .result_negative {
	background-color: #4CAF50;
}

#dashboard .result_green {
	background-color: #4CAF50;
}

#dashboard .result_positive {
	background-color:  red;
}

#dashboard .result_red {
	background-color:  red;
}

#dashboard .result_yellow {
	background-color:  orange;
}

#dashboard .result_none {
	background-color: gray;
	color: white;
}

#dashboard .big_font {
	font-size: 16pt;
}

#dashboard .big_number {
	font-size: 26pt;
	font-weight: 700;
}

